import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Gray900, Primary450, Primary500 } from '@mehilainen/mds-customer/colors'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DCNotification,
  NotificationLinkType,
  NotificationStyle,
  SimpleNotification,
} from '../../../__generated__/api'
import { useIsMobile, useIsTablet } from '../../hooks/useBreakpoint'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import { stripTagsAllowBasicHtml } from '../../utils/xss'
import { ColumnFlex, RowFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import { Text } from '../Typography/Typography'

import BaseTile from './BaseTile'
import { ThreeColumnGrid } from './TimeslotTile'

const Container = styled(RowFlex)<{ isMobile: boolean }>`
  gap: ${scale(2)};
`

const ExternalIcon = styled.img<{ isMobile: boolean; isTablet: boolean }>`
  filter: invert(17%) sepia(94%) saturate(6592%) hue-rotate(155deg) brightness(98%) contrast(101%);
  width: 36px;
  height: 36px;
  margin-left: 4px;
`
ExternalIcon.defaultProps = { alt: '', role: 'presentation' }

export const NotificationMobileBaseTile = styled(BaseTile)`
  gap: ${scale(1.5)};

  ${Container} {
    flex-basis: 80%;
  }

  ${ExternalIcon} {
    flex-basis: 56px;
  }
`

export const NotificationDesktopBaseTile = styled(BaseTile)<{ noIcon: boolean }>`
  ${() => ThreeColumnGrid}
  ${(props) =>
    props.noIcon
      ? css`
          grid-template-columns: 1fr auto;
          @media (min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px) {
            grid-template-columns: 1fr auto;
          }
        `
      : css`
          grid-template-columns: 50px 1fr auto;
          @media (min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px) {
            grid-template-columns: 50px 1fr auto;
          }
        `}

  &:focus-within {
    border-color: ${Primary450};
  }
`

const TextContainer = styled(ColumnFlex)`
  gap: ${scale(0.75)};
  justify-content: center;
`

const StyledButton = styled(Button)`
  align-self: flex-start;
`
StyledButton.defaultProps = { variant: 'outlined' }

interface DigitalClinicTileProps {
  notification: DCNotification
  onClick(queueId: string): void
}

export const DigitalClinicTile: React.FC<React.PropsWithChildren<DigitalClinicTileProps>> = ({
  notification,
  onClick,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  if (isMobile) {
    return (
      <NotificationMobileBaseTile
        role="button"
        tabIndex={0}
        onClick={() => onClick(notification.queueId)}
        onKeyPress={(event) => event.key === 'Enter' && onClick(notification.queueId)}
      >
        {notification.icon && (
          <ExternalIcon
            src={`${process.env.REACT_APP_NODE_ICON_URL}${notification.icon}.svg`}
            isMobile={true}
            isTablet={false}
          />
        )}
        <Container isMobile={true}>
          <TextContainer>
            <Text $size={400} $height="Medium" $weight="Medium">
              {notification.title}
            </Text>
            <Text $size={200} $height="Medium" $weight="Regular">
              {notification.content}{' '}
              {t('component.digitalClinicTile.queueTimeWithMinutes', {
                min: notification.queueLength,
              })}
            </Text>
          </TextContainer>
        </Container>

        <AngleRight htmlColor={Primary500} fontSize="large" />
      </NotificationMobileBaseTile>
    )
  }
  return (
    <NotificationDesktopBaseTile noIcon={!notification.icon}>
      {notification.icon && (
        <ExternalIcon
          src={`${process.env.REACT_APP_NODE_ICON_URL}${notification.icon}.svg`}
          isMobile={false}
          isTablet={isTablet}
        />
      )}
      <Container isMobile={false}>
        <TextContainer>
          <Text $color={Gray900} $height="Medium" $weight="Medium" $size={400}>
            {notification.title}
          </Text>
          <Text $size={300}>
            {notification.content}{' '}
            {t('component.digitalClinicTile.queueTimeWithMinutes', {
              min: notification.queueLength,
            })}
          </Text>
        </TextContainer>
      </Container>
      <StyledButton onClick={() => onClick(notification.queueId)}>
        {notification.ctaText}
      </StyledButton>
    </NotificationDesktopBaseTile>
  )
}

interface SimpleNotificationTileProps {
  notification: SimpleNotification
  buttonText?: string
  onClick?(): void
}

export const SimpleNotificationTile: React.FC<
  React.PropsWithChildren<SimpleNotificationTileProps>
> = ({ notification, buttonText, onClick }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const onClickOverride = useCallback(() => {
    if (onClick) {
      onClick()
      return
    }

    if (notification.link) {
      window.open(notification.link, notification.isInternalLink ? '_self' : '_blank')
    }
  }, [onClick, notification.link, notification.isInternalLink])

  if (isMobile) {
    return (
      <NotificationMobileBaseTile
        role={onClick ? 'button' : 'link'}
        tabIndex={0}
        onClick={onClickOverride}
        onKeyPress={(event) => event.key === 'Enter' && onClickOverride()}
      >
        {notification.icon && (
          <ExternalIcon
            src={`${process.env.REACT_APP_NODE_ICON_URL}${notification.icon}.svg`}
            isMobile={true}
            isTablet={false}
          />
        )}
        <Container isMobile={true}>
          <TextContainer>
            <Text $size={400} $height="Medium" $weight="Medium">
              {notification.title}
            </Text>
            {Boolean(
              notification.content ||
                (notification.link && notification.linkType === NotificationLinkType.InlineLink)
            ) && (
              <Text $size={200} $height="Medium" $weight="Regular">
                {notification.content}{' '}
                {notification.link && notification.linkType === NotificationLinkType.InlineLink && (
                  <Text
                    as="a"
                    href={notification.link}
                    target={notification.isInternalLink ? '_self' : '_blank'}
                    $size={200}
                    $color={Primary500}
                  >
                    {notification.linkText}
                  </Text>
                )}
              </Text>
            )}
          </TextContainer>
        </Container>

        {notification.link && notification.linkType === NotificationLinkType.InlineLink ? (
          <div />
        ) : (
          <AngleRight htmlColor={Primary500} fontSize="large" />
        )}
      </NotificationMobileBaseTile>
    )
  }
  return (
    <NotificationDesktopBaseTile noIcon={!notification.icon}>
      {notification.icon && (
        <ExternalIcon
          src={`${process.env.REACT_APP_NODE_ICON_URL}${notification.icon}.svg`}
          isMobile={false}
          isTablet={isTablet}
        />
      )}
      <Container isMobile={false}>
        <TextContainer>
          <Text $color={Gray900} $height="Medium" $weight="Medium" $size={400}>
            {notification.title}
          </Text>
          {Boolean(
            notification.content ||
              (notification.link && notification.linkType === NotificationLinkType.InlineLink)
          ) && (
            <Text $size={300}>
              {notification.content}{' '}
              {notification.link && notification.linkType === NotificationLinkType.InlineLink && (
                <Text
                  as="a"
                  href={notification.link}
                  target={notification.isInternalLink ? '_self' : '_blank'}
                  $size={300}
                  $color={Primary500}
                >
                  {notification.linkText}
                </Text>
              )}
            </Text>
          )}
        </TextContainer>
      </Container>

      {(notification.link && notification.linkType === NotificationLinkType.Button) ||
      (buttonText && onClick) ? (
        <StyledButton onClick={onClickOverride}>{buttonText ?? notification.linkText}</StyledButton>
      ) : (
        <div />
      )}
    </NotificationDesktopBaseTile>
  )
}

const ModalContent = styled(ColumnFlex)`
  gap: ${scale(3)};

  h2 {
    font-weight: 500;
  }
`

interface WalkInNotificationTileProps {
  notification: SimpleNotification
}

export const WalkInNotificationTile: React.FC<
  React.PropsWithChildren<WalkInNotificationTileProps>
> = ({ notification }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  if (!notification.linkList) {
    return <SimpleNotificationTile notification={notification} />
  }

  return (
    <>
      <SimpleNotificationTile
        notification={notification}
        buttonText={t('component.walkInNotificationTile.openModalText')}
        onClick={() => setModalOpen(true)}
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContent>
          <Text as="h1" $size={450} $weight="Medium" $height="Medium">
            {notification.title}
          </Text>
          {notification.linkList.map((link, index) => (
            <ColumnFlex key={`link-${link.link}-${index}`}>
              <ColumnFlex
                dangerouslySetInnerHTML={{
                  __html: stripTagsAllowBasicHtml(link.linkText),
                }}
              />
              <Text as="a" href={link.link} target="_blank">
                {t('component.walkInNotificationTile.modalLinkText')}
              </Text>
            </ColumnFlex>
          ))}
        </ModalContent>
      </Modal>
    </>
  )
}

export const isDCNotification = (
  value: DCNotification | SimpleNotification
): value is DCNotification => {
  return 'queueId' in value
}

export const isSimpleNotification = (
  value: DCNotification | SimpleNotification
): value is SimpleNotification => {
  return !('queueId' in value)
}

export const isWalkInNotification = (
  value: DCNotification | SimpleNotification
): value is SimpleNotification => {
  return isSimpleNotification(value) && value.style === NotificationStyle.WalkIn
}

interface Props {
  notification: DCNotification | SimpleNotification
  onDCNotificationClick(queueId: string): void
}

const NotificationTile: React.FC<React.PropsWithChildren<Props>> = ({
  notification,
  onDCNotificationClick,
}) => {
  if (isDCNotification(notification)) {
    return <DigitalClinicTile notification={notification} onClick={onDCNotificationClick} />
  }
  if (isSimpleNotification(notification)) {
    if (notification.style === NotificationStyle.WalkIn) {
      return <WalkInNotificationTile notification={notification} />
    }
    return <SimpleNotificationTile notification={notification} />
  }
  return null
}

export default NotificationTile
