import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Black, Gray900, Primary500, Attention500 } from '@mehilainen/mds-customer/colors'
import { CircleInfo } from '@mehilainen/mds-customer/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import Avatar, { resourceListImage } from '../Avatar/Avatar'
import { CenteredRowFlex, ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

type VariantOptions = 'XS' | 'S' | 'M' | 'L'
export interface Props {
  name: string
  title: string
  isResourceList?: boolean
  image?: string | null
  rating?: number
  allowsNewKelaClients?: boolean
  variant?: VariantOptions
  className?: string
  onClick?(): void
  extraElement?: React.ReactNode
}

const PointerCursor = css`
  cursor: pointer;
`

const VerticalContainer = styled(ColumnFlex)`
  max-width: 280px;
  gap: ${scale(1.5)};
`

const Container = styled(CenteredRowFlex)<{ isClickable: boolean }>`
  gap: ${scale(2)};
  ${(props) => props.isClickable && PointerCursor}
`

const TextContainer = styled(ColumnFlex)`
  gap: ${scale(0.5)};
  align-items: start;
  text-align: start;
`

const TextWithIconContainer = styled(Text)<{ $align: 'center' | 'flex-start' }>`
  display: flex;
  align-items: ${(props) => props.$align};
  gap: ${scale(1)};
`

const Title = styled(Text)`
  word-break: break-word;
`

const AppointmentSpecialistDetails: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  title,
  isResourceList,
  image,
  rating,
  allowsNewKelaClients,
  variant = 'L',
  className,
  onClick,
  extraElement,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const onClickHandler = useCallback(
    (event: any) => {
      event.stopPropagation()
      onClick?.()
    },
    [onClick]
  )

  const onKeyHandler = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        event.stopPropagation()
        onClick?.()
      }
    },
    [onClick]
  )

  const isClickable = !!onClick && !isResourceList

  return (
    <VerticalContainer>
      <Container isClickable={isClickable} className={className}>
        <Avatar
          width={variant === 'XS' ? '40px' : variant === 'L' ? '64px' : '50px'}
          height={variant === 'XS' ? '50px' : variant === 'L' ? '84px' : '62px'}
          ratingFontSize="11px"
          ratingPadding="4px"
          borderRadius="10px"
          image={isResourceList ? resourceListImage : image}
          rating={isResourceList ? undefined : rating}
          onClick={isClickable ? onClickHandler : undefined}
          onKeyPress={isClickable ? onKeyHandler : undefined}
          tabIndex={-1}
        />
        <TextContainer>
          <TextWithIconContainer
            $size={400}
            $height="Medium"
            $weight="Medium"
            $color={isClickable ? `${Primary500}` : `${Black}`}
            $align="center"
            role={isClickable ? 'button' : undefined}
            onClick={isClickable ? onClickHandler : undefined}
            onKeyPress={isClickable ? onKeyHandler : undefined}
            tabIndex={isClickable ? 0 : -1}
            data-analytics-path="doctor_modal_click_id_13070"
          >
            <span data-cy="specialistName">{name}</span>
            {isClickable && <CircleInfo />}
          </TextWithIconContainer>
          {(variant === 'M' || variant === 'L') && (
            <Title $size={300} $height="Medium" $weight="Regular" $color={Gray900}>
              {title}
            </Title>
          )}
          {!isMobile && allowsNewKelaClients === false && (
            <TextWithIconContainer $size={200} $color={Attention500} $align="flex-start">
              <CircleInfo />
              <span>{t('component.appointmentList.disallowsKelaClients')}</span>
            </TextWithIconContainer>
          )}
          {extraElement}
        </TextContainer>
      </Container>
      {isMobile && allowsNewKelaClients === false && (
        <TextWithIconContainer $size={200} $color={Attention500} $align="flex-start">
          <CircleInfo />
          <span>{t('component.appointmentList.disallowsKelaClients')}</span>
        </TextWithIconContainer>
      )}
    </VerticalContainer>
  )
}

export default AppointmentSpecialistDetails
