import { Dayjs } from 'dayjs'

import { PublicNode } from '../__generated__/api'
import { AppointmentDuration } from '../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { GroupedFlexibleScheduleOption } from '../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'
import { FilterOptions, Gender } from '../common/components/FilterOptions/types'
import {
  AppointmentSearchMode,
  defaultSelectedAppointmentTypes,
  defaultSelectedDurations,
  defaultSelectedLanguage,
  defaultSelectedPatientAgeGroup,
  defaultSelectedTimeRanges,
} from '../state/search/atoms'

export type ParsedURL = {
  isOHC: boolean
  isDental: boolean
  nodeId?: string
  node?: PublicNode
  parentNode?: PublicNode
  practitionerId?: number
  noLanding?: boolean
  callbackOpen?: boolean
  date?: Dayjs
  location?: string[]
  filterOptions?: Partial<FilterOptions>
  appointmentSearchMode?: AppointmentSearchMode
  isFromApp: boolean
  hasInsurance: boolean
  noServiceSelect: boolean
  pathname: string
  noteModal?: string
  modalAction?: string
  preselectedStandardScheduleOption?: AppointmentDuration
  openServiceSelect?: boolean
}

export type SelectedNode = {
  nodeId: string
}

export type SelectedPractitioner = {
  practitionerId: number
  option?: AppointmentDuration | GroupedFlexibleScheduleOption
}

export const defaultFilterOptions = {
  appointmentTypes: defaultSelectedAppointmentTypes,
  timeRanges: defaultSelectedTimeRanges,
  gender: Gender.UNSPECIFIED,
  language: defaultSelectedLanguage,
  patientAgeGroup: defaultSelectedPatientAgeGroup,
  durations: defaultSelectedDurations,
}

export enum SearchTargetValue {
  Node = 'node',
  Practitioner = 'practitioner',
}

export type SearchTarget =
  | { id: string; value: SearchTargetValue.Node }
  | { id: number; value: SearchTargetValue.Practitioner }

export type ResolvedService = PublicNode | { type: 'practitioner'; id: number } | undefined

export const isPublicNode = (service: ResolvedService): service is PublicNode => {
  return (
    service !== undefined && 'id' in service && 'type' in service && typeof service.id === 'string'
  )
}

export const isPractitioner = (
  service: ResolvedService
): service is { type: 'practitioner'; id: number } => {
  return service !== undefined && service.type === 'practitioner'
}
