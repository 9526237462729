import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Black,
  White,
  Primary500,
  Gray300,
  Gray600,
  Gray900,
  Primary100,
  Gray500,
  Primary400,
  Gray800,
  Primary700,
  Primary200,
} from '@mehilainen/mds-customer/colors'
import { AngleLeft, AngleRight } from '@mehilainen/mds-customer/icons'
import { IconButton, Button } from '@mui/material'
import { Skeleton } from '@mui/material'
import { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import dayjs from '../../../common/utils/dayjs/dayjs'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { transientOptions } from '../../utils/emotion'
import { scale } from '../../utils/scale'
import { CenteredColumnFlex, VisuallyHidden } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

import DatePickerLegend from './DatePickerLegend'

type CalendarVariant = 'default' | 'small'

const CalendarDaySize: Record<CalendarVariant, string> = {
  default: '44px',
  small: '40px',
}

export type CalendarAvailability = {
  date: Dayjs
  isAvailable: boolean
}

const Container = styled(CenteredColumnFlex)`
  ${DatePickerLegend} {
    margin-top: ${scale(2)};
  }
`

function chunk<T>(array: T[], chunkSize: number): T[][] {
  return array.reduce<T[][]>(
    (acc, curr) => {
      if (acc[acc.length - 1].length === chunkSize) {
        acc.push([curr])
      } else {
        acc[acc.length - 1].push(curr)
      }
      return acc
    },
    [[]]
  )
}

const MonthTable = styled.table<{ $variant: CalendarVariant; $isMobile: boolean }>`
  text-align: center;
  border-collapse: separate;
  border-spacing: ${(props) =>
    props.$variant === 'small' ? '3px' : props.$isMobile ? '8px' : '10px'};
`

const MonthContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > * {
    padding: 10px;
  }
`

const DayName = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${Primary500};
`

const ButtonWrapper = styled.div<DayProps>`
  border-radius: 8px;
  ${(props) =>
    props.$current &&
    css`
      background: ${Primary700};
    `}
`

const SkeletonWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
`

const CalendarDay = styled(Button, transientOptions)<DayProps & { $variant: CalendarVariant }>`
  background-color: inherit;
  padding: 0px;
  border-width: 0px;
  border-radius: 8px;
  width: min(${(props) => CalendarDaySize[props.$variant]}, calc(10vw));
  height: ${(props) => CalendarDaySize[props.$variant]};

  :hover {
    background-color: ${Primary200};
  }

  :disabled {
    background: transparent;
    :hover {
      background: transparent;
      cursor: default;
      border: none;
    }
    span {
      color: ${Gray600};
    }
  }

  ${(props) => (props.$gone || !props.$available) && DayUnavailable}
  ${(props) => props.$current && DayCurrent}
  ${(props) => props.$available && DayAvailable}
  ${(props) => props.$selected && DaySelected}
  ${(props) => props.$pending && DayPending}
`

const DaySelected = css`
  background-color: ${Primary500};
  border-color: transparent;

  span {
    color: ${White};
  }

  &:hover {
    span {
      color: ${White};
    }

    border-color: transparent;
    background-color: ${Primary400};
  }
`

const DayUnavailable = css`
  background-color: transparent;
  span {
    color: ${Gray900};
  }

  :hover {
    background-color: transparent;
    border: 1px solid ${Gray500};
  }
`

const DayCurrent = css`
  background: ${White};
  border: 1px solid ${White};
  clip-path: polygon(100% 150%, -50% 0, 150% -50%);
  span {
    color: ${Gray900};
  }
`

const DayAvailable = css`
  border: 1px solid ${Primary500};
  background-color: ${Primary100};
  span {
    color: ${Gray800};
  }
`

const DayPending = css`
  border-style: none;
  background-color: inherit;
  span {
    color: ${Gray300};
  }
`

const MonthLabel = styled(Text)`
  text-transform: capitalize;
`

interface DayProps {
  $gone?: boolean
  $current?: boolean
  $selected?: boolean
  $available?: boolean
  $pending?: boolean
}

const getDates = (selectedMonth: Dayjs): Dayjs[] => {
  const endDate = selectedMonth.endOf('month').add(1, 'week').isoWeekday(7) // 7 = Sunday
  const startDate = selectedMonth.startOf('month').isoWeekday(1) // 1 = Monday
  let day: Dayjs = startDate
  const dates: Dayjs[] = []
  // comparison by hours to include the endDate
  while (day.isBefore(endDate, 'hours')) {
    dates.push(day)
    day = day.add(1, 'day')
  }
  return dates
}

interface Props {
  variant?: CalendarVariant
  selectedDate: Dayjs
  setSelectedDate(date: Dayjs): void
  calendarDataFetcher(date: Dayjs): Promise<CalendarAvailability[]>
  onDateSelect?(date: Dayjs): void
}

const DatePickerCalendar: React.FC<React.PropsWithChildren<Props>> = ({
  selectedDate,
  setSelectedDate,
  calendarDataFetcher,
  onDateSelect,
  variant = 'default',
}) => {
  const { t, i18n } = useTranslation()
  const isMobile = useIsMobile()
  const monthTableId = useMemo(() => `datePickerCalendar-MonthTable-${uuid()}`, [])

  const [availability, setAvailability] = useState<CalendarAvailability[] | undefined>()
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(
    selectedDate.isSame(dayjs(), 'month')
      ? dayjs().utc().startOf('day')
      : selectedDate.utc().startOf('month')
  )
  const datesToDisplay = getDates(selectedMonth.utc())
  const loading = !availability

  // Update visible month to match the selected date on the first(ish) render
  const [autoUpdateMonth, setAutoUpdateMonth] = useState(true)
  useEffect(() => {
    if (autoUpdateMonth && !selectedMonth.isSame(selectedDate, 'month')) {
      setSelectedMonth(selectedDate.utc().startOf('month'))
    }
  }, [selectedDate, selectedMonth, setSelectedMonth, autoUpdateMonth])
  setTimeout(() => setAutoUpdateMonth(false), 1000)

  useEffect(() => {
    setAvailability(undefined)
    const fetchData = async () => {
      const data = await calendarDataFetcher(selectedMonth)
      setAvailability(data)
    }
    void fetchData()
  }, [calendarDataFetcher, selectedMonth])

  const selectPrevMonth = useCallback(() => {
    const prevMonth = selectedMonth.utc().subtract(1, 'month').startOf('month')
    setSelectedMonth(prevMonth.isSame(dayjs(), 'month') ? dayjs().utc().startOf('day') : prevMonth)
  }, [setSelectedMonth, selectedMonth])

  const selectNextMonth = useCallback(() => {
    const nextMonth = selectedMonth.utc().add(1, 'month').startOf('month')
    setSelectedMonth(nextMonth)
  }, [setSelectedMonth, selectedMonth])

  return (
    <Container data-cy="datePickerCalendar">
      <MonthContainer>
        <IconButton
          name="prev"
          onClick={selectPrevMonth}
          aria-label={t('component.datePickerCalendar.prevMonth')}
          disabled={selectedMonth.isSame(dayjs(), 'month')}
          size="large"
        >
          <AngleLeft htmlColor={selectedMonth.isSame(dayjs(), 'month') ? Gray500 : Black} />
        </IconButton>
        <MonthLabel
          as="label"
          $size={variant === 'small' ? 400 : 500}
          $height="Medium"
          $weight="Medium"
          htmlFor={monthTableId}
          aria-live="polite"
          aria-atomic
        >
          {selectedMonth.locale(i18n.language).format('MMMM YYYY')}
        </MonthLabel>
        <IconButton
          name="next"
          onClick={selectNextMonth}
          aria-label={t('component.datePickerCalendar.nextMonth')}
          disabled={false}
          size="large"
        >
          <AngleRight htmlColor={Black} />
        </IconButton>
      </MonthContainer>
      <VisuallyHidden aria-live="polite" aria-atomic>
        {loading
          ? t('component.datePickerCalendar.loading')
          : t('component.datePickerCalendar.loaded')}
      </VisuallyHidden>
      <MonthTable id={monthTableId} $isMobile={isMobile} $variant={variant} role="grid">
        <thead>
          <tr>
            {[...new Array(7)].map((_, index) => {
              const dayDate = selectedMonth.isoWeekday(index + 1)
              return (
                <th key={index}>
                  <DayName aria-hidden>{dayDate.locale(i18n.language).format('dd')}</DayName>
                  <VisuallyHidden as="span">
                    {dayDate.locale(i18n.language).format('dddd')}
                  </VisuallyHidden>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {chunk(datesToDisplay, 7).map((week, index) => (
            <tr key={index} aria-hidden={loading ? 'true' : undefined}>
              {week.map((day) => {
                const gone = dayjs().isAfter(day, 'date')
                const available =
                  availability?.find((a) => a.date.isSame(day, 'date'))?.isAvailable ?? false
                const isSelected = selectedDate?.isSame(day, 'date')
                const isToday = dayjs().isSame(day, 'date')

                const isPrevMonth = day.isBefore(selectedMonth, 'month')
                const isNextMonth = day.isAfter(selectedMonth, 'month')

                return (
                  <td
                    key={`td-${day.toISOString()}`}
                    role="gridcell"
                    aria-selected={isSelected ? 'true' : undefined}
                  >
                    {loading ? (
                      <SkeletonWrapper>
                        <Skeleton
                          variant="rectangular"
                          width={`min(${CalendarDaySize[variant]}, calc(10vw))`}
                          height={CalendarDaySize[variant]}
                        />
                      </SkeletonWrapper>
                    ) : (
                      <ButtonWrapper
                        $current={isToday}
                        $selected={isSelected}
                        $available={available}
                      >
                        <CalendarDay
                          disabled={gone}
                          key={day.toISOString()}
                          onClick={() => {
                            if (isNextMonth) {
                              selectNextMonth()
                            }
                            if (isPrevMonth) {
                              selectPrevMonth()
                            }
                            setSelectedDate(day)
                            onDateSelect?.(day)
                          }}
                          $gone={gone}
                          $selected={isSelected}
                          $available={available}
                          $current={isToday}
                          $pending={!availability}
                          $variant={variant}
                          aria-pressed={isSelected}
                          aria-current={isToday ? 'date' : undefined}
                        >
                          <Text $size={300} $height="Small" aria-hidden>
                            {day.format('D')}
                          </Text>
                          <VisuallyHidden>
                            {available
                              ? t(`component.datePickerCalendar.dayHasAvailability`, {
                                  date: day.format('LL'),
                                })
                              : t(`component.datePickerCalendar.dayHasNoAvailability`, {
                                  date: day.format('LL'),
                                })}
                          </VisuallyHidden>
                        </CalendarDay>
                      </ButtonWrapper>
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </MonthTable>
      <DatePickerLegend variant={variant === 'small' ? 'vertical' : 'horizontal'} />
    </Container>
  )
}

export default DatePickerCalendar
