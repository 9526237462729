import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import qs from 'qs'
import { fromPromise } from 'xstate'

import {
  AppointmentType,
  LanguageResult,
  NodeAutocompleteResult,
  PatientAgeGroup,
  PublicNode,
  SupportedLanguage,
  TimeRange,
} from '../__generated__/api'
import { AppointmentDuration } from '../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { FilterOptions, Gender } from '../common/components/FilterOptions/types'
import { SearchTreeItem } from '../common/components/Search/types'
import i18n from '../common/i18n/i18n'
import api from '../common/services/api'
import { fetchEhoitoEvents } from '../common/services/omApiFetch'
import { AppointmentSearchMode, defaultDentalSearchNodeId } from '../state/search/atoms'

import { ParsedURL, ResolvedService } from './types'

const getNode = async (
  nodeId: string,
  lang: SupportedLanguage,
  isOhc: boolean
): Promise<PublicNode | undefined> => {
  try {
    const res = await api.v1.searchNode({
      nodeId,
      lang,
      isOhc,
    })
    return res.data[0]
  } catch {
    return undefined
  }
}

const getParentNode = async (
  childNode: PublicNode | undefined,
  lang: SupportedLanguage,
  isOhc: boolean
): Promise<PublicNode | undefined> => {
  if (!childNode || !childNode.parentId) {
    return undefined
  }

  return getNode(childNode.parentId, lang, isOhc)
}

export const parseURL = fromPromise(async (): Promise<ParsedURL> => {
  const pathname = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search)

  const isDental = searchParams.has('noLanding') && searchParams.get('noLanding') === 'dental'
  const isOHC = pathname.includes('tyoterveys') || pathname.includes('tep')
  const lang =
    searchParams.has('lang') &&
    Object.values(SupportedLanguage).includes(searchParams.get('lang') as SupportedLanguage)
      ? (searchParams.get('lang') as SupportedLanguage)
      : SupportedLanguage.Fi

  await i18n.changeLanguage(lang)

  let nodeData = {}
  if (searchParams.has('s')) {
    const node = await getNode(searchParams.get('s') as string, lang, isOHC)
    const parentNode = await getParentNode(node, lang, isOHC)
    nodeData = {
      nodeId: searchParams.get('s') as string,
      node,
      parentNode,
    }
  } else if (isDental) {
    const node = await getNode(defaultDentalSearchNodeId, lang, isOHC)
    const parentNode = await getParentNode(node, lang, isOHC)
    nodeData = {
      nodeId: defaultDentalSearchNodeId,
      node,
      parentNode,
    }
  }

  let practitionerData = {}
  if (searchParams.has('p')) {
    const practitionerId = Number(searchParams.get('p'))
    practitionerData = { practitionerId }

    if (searchParams.has('so')) {
      const standardScheduleOptionString = searchParams.get('so')!
      const standardScheduleOption = isNaN(parseInt(standardScheduleOptionString as string))
        ? (standardScheduleOptionString as AppointmentDuration)
        : (parseInt(standardScheduleOptionString as string) as AppointmentDuration)

      practitionerData = {
        ...practitionerData,
        preselectedStandardScheduleOption: standardScheduleOption,
      }
    }
  }

  // FIXME:
  // - useNodeLinkings logic?

  const parsed = qs.parse(searchParams.toString(), { arrayLimit: 1000 })

  // Search filter options
  const filterOptions: Partial<FilterOptions> = {}

  if (parsed.ty) {
    filterOptions.appointmentTypes = parsed.ty as unknown as AppointmentType[]
  }
  if (parsed.tr) {
    filterOptions.timeRanges = parsed.tr as unknown as TimeRange[]
  }
  if (parsed.g) {
    filterOptions.gender = parsed.g as unknown as Gender
  }
  if (parsed.la) {
    filterOptions.language = parsed.la as unknown as LanguageResult
  }
  if (parsed.pag) {
    const _ageGroups = parsed.pag as unknown as PatientAgeGroup
    filterOptions.patientAgeGroup = { ..._ageGroups, id: Number(_ageGroups.id) }
  }

  const isFromApp = searchParams.has('isFromApp') || Cookies.get('MOBILE') === '1'

  const hasInsurance =
    searchParams.has('in') ||
    (searchParams.has('noLanding') && searchParams.get('noLanding') === 'insurance')

  const noServiceSelect = searchParams.has('noServiceSelect')

  return {
    isOHC,
    isDental,
    filterOptions,
    ...nodeData,
    ...practitionerData,
    ...(searchParams.has('d') ? { date: dayjs(searchParams.get('d')) } : {}),
    ...(searchParams.has('mo')
      ? { appointmentSearchMode: searchParams.get('mo') as AppointmentSearchMode }
      : {}),
    ...(searchParams.has('mode')
      ? { appointmentSearchMode: searchParams.get('mode') as AppointmentSearchMode }
      : {}),
    ...(parsed.lo ? { location: parsed.lo as string[] } : {}),
    noLanding: searchParams.has('noLanding'),
    callbackOpen: searchParams.has('callbackOpen'),
    isFromApp,
    hasInsurance,
    noServiceSelect,
    pathname,
    ...(searchParams.has('modal') ? { noteModal: searchParams.get('modal') as string } : {}),
    ...(searchParams.has('modalAction')
      ? { modalAction: searchParams.get('modalAction') as string }
      : {}),
    openServiceSelect: searchParams.has('serviceSelect'),
  }
})

export const fetchNode = fromPromise(
  async ({
    input,
  }: {
    input: { lang: SupportedLanguage; nodeId: string; isOhc: boolean }
  }): Promise<PublicNode | undefined> => {
    return getNode(input.nodeId, input.lang, input.isOhc)
  }
)

export const resolveServiceSelection = fromPromise(
  async ({
    input,
  }: {
    input: {
      service: Array<SearchTreeItem<PublicNode>> | PublicNode | NodeAutocompleteResult
      lang: SupportedLanguage
      isOHC: boolean
    }
  }): Promise<ResolvedService> => {
    const { service, lang, isOHC } = input

    // Received breadcrumb
    if (Array.isArray(service)) {
      if (service.length === 0) {
        return undefined
      }
      return service[0].item
    }

    // Received node
    if ('id' in service) {
      return service
    }

    // Autocomplete handlers
    if (service.practitionerData) {
      return { type: 'practitioner', id: service.practitionerData.id }
    }

    if (service.searchKeywordData) {
      const res = await api.v1.searchNode({
        keywordId: service.searchKeywordData.id,
        lang,
        isOhc: isOHC,
        onlyListed: true,
      })
      return res.data[0]
    }

    if (service.nodeData) {
      const res = await api.v1.searchNode({
        nodeId: service.nodeData.id,
        lang,
        isOhc: isOHC,
        onlyListed: true,
      })
      return res.data[0]
    }

    if (service.groupData) {
      const res = await api.v1.searchNode({
        keyword: service.keyword,
        lang,
        isOhc: isOHC,
        onlyListed: true,
      })
      return res.data[0]
    }

    return undefined
  }
)

export const fetchPractitionerDetails = fromPromise(
  async ({
    input,
  }: {
    input: { lang: SupportedLanguage; practitionerId: number; isOHC: boolean }
  }) => {
    const res = await api.v1.getPractitionerDetails(input)
    return res.data
  }
)

export const fetchOMEvents = fromPromise(async () => {
  return await fetchEhoitoEvents()
})
