import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import {
  AppointmentSearchResult,
  SearchAppointmentSuggestionType,
  SupportedLanguage,
} from '../../../__generated__/api'
import { useApi } from '../../../common/hooks/useApi'
import { useOHC } from '../../../common/hooks/useOHC'
import api from '../../../common/services/api'
import { AppointmentSearchMode } from '../../../state/search/atoms'
import { allLocationsSelection } from '../../../state/search/atoms'
import { useAppointmentSearchMode, useSearchParams } from '../../../xstate/selectors'

export const getDateAfter = (appointments: AppointmentSearchResult[]): Date => {
  if (appointments.length === 0) {
    return dayjs().add(1, 'day').startOf('day').toDate()
  }

  return dayjs(
    appointments.map((appointment) => new Date(appointment.time).getTime()).sort((a, b) => b - a)[0]
  )
    .add(1, 'day')
    .startOf('day')
    .toDate()
}

const useSearchResults = () => {
  const { i18n } = useTranslation()
  const { nodeSearchParams, practitionerSearchParams, isPractitionerSearch, isNodeSearch } =
    useSearchParams()

  const appointmentSearchMode = useAppointmentSearchMode()
  const { isOHCSide, ohcAllowedStatus } = useOHC()
  const isCallbackSearch = appointmentSearchMode === AppointmentSearchMode.CALLBACK

  const shouldMakeRequest = isOHCSide ? ohcAllowedStatus === 'allowed' : true
  const shouldMakePractitionerRequest =
    isPractitionerSearch &&
    shouldMakeRequest &&
    practitionerSearchParams &&
    Boolean(practitionerSearchParams?.practitionerId)

  const apiCallParams = {
    ...nodeSearchParams,
    lang: i18n.language as SupportedLanguage,
  }
  const {
    data: nodeSearchResultsRaw,
    error: nodeSearchError,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: [api.v1.nodeAppointmentSearch.name, apiCallParams],
    queryFn: ({ pageParam }) =>
      api.v1.nodeAppointmentSearch({ ...apiCallParams, offset: pageParam }),
    enabled: isNodeSearch && shouldMakeRequest,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (lastPage.data.appointments.length === 0 || !lastPage.data.hasMoreData) {
        return undefined
      }
      return (lastPageParam ?? 0) + lastPage.data.appointments.length
    },
  })

  const nodeSearchPending = isFetching && !isFetchingNextPage
  const nodeSearchResults =
    nodeSearchResultsRaw && nodeSearchResultsRaw.pages.length > 0
      ? nodeSearchResultsRaw.pages
          .map((page) => page.data)
          .reduce(
            (acc, val) => ({
              appointments: [...acc.appointments, ...val.appointments],
              suggestions: val.suggestions,
              hasMoreData: val.hasMoreData,
            }),
            { appointments: [], suggestions: null, hasMoreData: false }
          )
      : { appointments: [], suggestions: null, hasMoreData: false }

  const { data: nodeSearchSuggestions } = useApi(
    api.v1.nodeSearchSuggestions,
    {
      types: [
        SearchAppointmentSuggestionType.Date,
        SearchAppointmentSuggestionType.Node,
        ...(nodeSearchParams.locationIds !== allLocationsSelection
          ? [SearchAppointmentSuggestionType.Location]
          : []),
      ],
      datesAfter: getDateAfter(nodeSearchResults.appointments).toISOString(),
      nodeSearch: {
        ...nodeSearchParams,
        lang: i18n.language as SupportedLanguage,
      },
    },
    null,
    isNodeSearch && !isFetching && !isFetchingNextPage && !nodeSearchResults.hasMoreData
  )

  const { data: practitionerSearchResults, pending: practitionerSearchPending } = useApi(
    api.v1.practitionerAppointmentSearch,
    {
      ...practitionerSearchParams!,
      isOhc: isOHCSide,
      lang: i18n.language as SupportedLanguage,
    },
    { appointments: [], suggestions: null },
    shouldMakePractitionerRequest && !isCallbackSearch
  )

  const { data: callbackSearchResults, pending: callbackSearchPending } = useApi(
    api.v1.practitionerCallbackSearch,
    {
      practitionerId: practitionerSearchParams ? practitionerSearchParams.practitionerId : 0,
      startDate: practitionerSearchParams ? practitionerSearchParams.startDate : '',
      lang: i18n.language as SupportedLanguage,
    },
    { appointments: [] },
    shouldMakePractitionerRequest && isCallbackSearch
  )

  return {
    appointments: isCallbackSearch
      ? callbackSearchResults.appointments
      : isPractitionerSearch
      ? practitionerSearchResults.appointments
      : nodeSearchResults.appointments,
    suggestions: isCallbackSearch
      ? null
      : isPractitionerSearch
      ? practitionerSearchResults.suggestions
      : nodeSearchSuggestions,
    pending: isCallbackSearch
      ? callbackSearchPending
      : isPractitionerSearch
      ? practitionerSearchPending
      : nodeSearchPending,
    error: nodeSearchError,
    hasMoreData: Boolean(
      !isCallbackSearch && !isPractitionerSearch && nodeSearchResults.hasMoreData
    ),
    fetchNextPage: isNodeSearch ? fetchNextPage : undefined,
    isInitialSearch: isNodeSearch
      ? Boolean(nodeSearchResultsRaw?.pages.length === 1 && !isFetchingNextPage)
      : true,
    isFetchingNextPage: isNodeSearch && isFetchingNextPage,
  }
}

export default useSearchResults
