import styled from '@emotion/styled'
import { Event } from '@mehilainen/mds-customer'
import { Primary300 } from '@mehilainen/mds-customer/colors'
import { Tooth, Stethoscope, Briefcase, Headset } from '@mehilainen/mds-customer/icons'
import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useModalEvents } from '../../../xstate/events'
import { useIsMobile } from '../../hooks/useBreakpoint'
import useChangeLanguage from '../../hooks/useChangeLanguage'
import { getCustomerServiceNumber } from '../../services/customerService'
import * as Analytics from '../../utils/analytics'
import { scale } from '../../utils/scale'
import LangSelectButton from '../LangSelectButton/LangSelectButton'
import { ColumnFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import DesktopLogo from '../NavBar/desktopLogo.svg?react'
import { Text } from '../Typography/Typography'

// TODO: Remove these styles when https://porriainen.atlassian.net/browse/AV3-2886 is resolved
const StyledEvent = styled(Event)`
  *,
  *::after,
  *::before {
    box-sizing: unset;
  }
`

interface NavLinkProps {
  text: string
  description?: string
  icon: JSX.Element
  dataCy?: string
  onClick?: () => void
}

const NavLink: React.FC<React.PropsWithChildren<NavLinkProps>> = ({
  text,
  description,
  onClick,
  icon,
}) => {
  return (
    <StyledEvent
      component="button"
      sx={{ width: '100%' }}
      description={description}
      title={text}
      startIcon={icon}
      onClick={onClick}
    />
  )
}

const NavLinks = styled(ColumnFlex)`
  gap: ${scale(1)};
`

const TopContainer = styled.div`
  display: flex;
`

const LogoContainer = styled.div`
  margin-bottom: 30px;
`

const StyledLangSelectButton = styled(LangSelectButton)`
  margin-left: auto;
  margin-top: 3px;
`

const StyledH1 = styled.h1`
  font-size: 0.875rem;
  margin-bottom: 10px;
  margin-top: 10px;
`

const StyledH2 = styled.h2`
  font-size: 0.875rem;
  margin-bottom: 10px;
  margin-top: 30px;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

const StyledH3 = styled.h3`
  text-align: center;
  font-size: 0.875rem;
`

const StyledText = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  text-decoration: none;
  &:not(.no-link-styles) {
    &:link {
      text-decoration: none;
    }
  }
`

const LandingModal: React.FC<React.PropsWithChildren> = () => {
  const { changeLanguage: onLanguageSelect } = useChangeLanguage()
  const { t, i18n } = useTranslation()
  const isMobile = useIsMobile()
  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link
  const { openCallbackModal, openInsuranceModal, closeModal } = useModalEvents()

  const headerRef = useRef<HTMLHeadingElement>(null)
  useEffect(() => {
    headerRef.current?.focus()
  }, [])

  const LandingModalHeader: FC = () => (
    <StyledH1 ref={headerRef} tabIndex={-1}>
      {t('component.landingModal.heading')}
    </StyledH1>
  )

  return (
    <ResponsiveModal open={true} fullWidth maxWidth="md">
      {isMobile ? (
        <>
          <TopContainer>
            <LogoContainer>
              <DesktopLogo />
            </LogoContainer>
            <StyledLangSelectButton onLanguageSelect={onLanguageSelect} />
          </TopContainer>
          <LandingModalHeader />
        </>
      ) : (
        <TopContainer>
          <LandingModalHeader />
          <StyledLangSelectButton onLanguageSelect={onLanguageSelect} />
        </TopContainer>
      )}

      <NavLinks>
        <NavLink
          text={t('component.landingModal.privateLinkText')}
          description={t('component.landingModal.privateLinkDescription')}
          onClick={() => {
            Analytics.trackServiceSelection(Analytics.ServiceSelectionType.PRIVATE)
            closeModal()
          }}
          icon={<Stethoscope />}
          dataCy="landingModal-private"
        />
        <NavLink
          text={t('component.landingModal.insuranceLinkText')}
          description={t('component.landingModal.insuranceLinkDescription')}
          onClick={() => {
            Analytics.trackServiceSelection(Analytics.ServiceSelectionType.INSURANCE)
            openInsuranceModal()
          }}
          icon={<Stethoscope />}
        />
        <NavLink
          text={t('component.landingModal.occupationalLinkText')}
          description={t('component.landingModal.occupationalLinkDescription')}
          onClick={() => {
            Analytics.trackServiceSelection(Analytics.ServiceSelectionType.OCCUPATIONAL)
            window.location.assign(t('component.landingModal.occupationalLink'))
          }}
          icon={<Briefcase />}
        />
        <NavLink
          text={t('component.landingModal.dentalLinkText')}
          onClick={() => {
            Analytics.trackServiceSelection(Analytics.ServiceSelectionType.DENTAL)
            window.location.assign(t('component.landingModal.dentalLink'))
          }}
          icon={<Tooth />}
        />
      </NavLinks>
      <StyledH2>{t('component.landingModal.callbackHeading')}</StyledH2>
      <NavLink
        text={t('component.landingModal.callbackLinkText')}
        onClick={openCallbackModal}
        icon={<Headset />}
      />
      <ContactContainer>
        <StyledH3>{t('component.landingModal.contactHeading')}</StyledH3>
        <StyledText as="a" $color={Primary300} target="_blank" href={link.link}>
          {telNumber}
        </StyledText>
        <StyledH3>{t('component.landingModal.customerServicePhonePrice')}</StyledH3>
      </ContactContainer>
    </ResponsiveModal>
  )
}

export default LandingModal
